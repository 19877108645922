import 'flotiq-components-react/dist/index-including-tailwind.css';
import './src/style/global.css';
import '@fontsource/inter/600.css';
import '@fontsource/sora/400.css';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/darker-grotesque/300.css';
import '@fontsource/darker-grotesque/400.css';
import '@fontsource/darker-grotesque/500.css';

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

export const wrapRootElement = ({ element }) => (
  <HelmetProvider>
    {element}
  </HelmetProvider>
);